import Immutable from 'immutable';
// import store from 'store2';
// import { typeCastToKeyValueObject } from '../../utils/commonUtils';
import { typeCastResponse } from '../../utils/commonUtils';
import { configConstants } from '../constants/config.constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  message: null,
  loadErr: null,
  tenantConfig: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case configConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case configConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case configConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case configConstants.TENANT_CONFIG:
      return state.set('tenantConfig', typeCastResponse(action, 'data', 'object', {}));
    // Default
    default:
      return state;
  }
}
