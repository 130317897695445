import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '../../components/--primitives/pr-popup';
import { DEFAULT_TIMEZONE, DATE_FORMAT_MDY } from '../../utils/siteSpecificConstants';

const NotificationPopup = ({ isModalOpen, details, content, toggle, ref }) => {
  const { sender_name, createdAt } = details;
  return (
    <Popup className="notifications-pop" scrollContent popupRef={ref} isOpen={isModalOpen}>
      <PopupHeader toggle={toggle} close>
        <div className="title">{sender_name}</div>
      </PopupHeader>
      <PopupBody>{content}</PopupBody>
      <PopupFooter>
        <p className="date">{moment(createdAt).tz(DEFAULT_TIMEZONE).format(DATE_FORMAT_MDY)}</p>
        <p className="time">{moment(createdAt).tz(DEFAULT_TIMEZONE).format('hh:mm a')}</p>
      </PopupFooter>
    </Popup>
  );
};

NotificationPopup.propTypes = {
  isModalOpen: PropTypes.bool,
  details: PropTypes.shape(PropTypes.object),
  content: PropTypes.element,
  toggle: PropTypes.func,
  ref: PropTypes.element,
};

NotificationPopup.defaultProps = {
  isModalOpen: false,
  details: {},
  content: null,
  toggle: null,
  ref: null,
};

export default NotificationPopup;
