import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import store from 'store2';
import Routes from './routes';
import { storeLastPathName } from '../containers/auth/actions';

function PrivateRoute({ component: Component, dispatch, ...rest }) {
  const isAuthTokenFlag = !!store('SAS_CLIENT_TOKEN');
  const { computedMatch = {}, location: { pathname = '', search = '' } = {} } = rest;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  const { params = {} } = computedMatch;
  const { key } = params;
  if (!isAuthTokenFlag && pathname !== '/') {
    dispatch(storeLastPathName(`${pathname}${search}`));
  }

  let RedirectURL = <Redirect to="/hire" />;
  if (isAuthTokenFlag) RedirectURL = <Routes key={key} {...rest} />;
  else if (pathname !== '/') RedirectURL = <Redirect to="/login" />;

  return <Route {...rest} render={() => RedirectURL} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.element,
  dispatch: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  component: undefined,
};

export default connect()(PrivateRoute);
