export const jobConstants = {
  LOAD: 'job/LOAD',
  LOAD_INNER: 'job/LOAD_INNER',
  LOAD_SUCCESS: 'job/LOAD_SUCCESS',
  LOAD_TAB: 'job/LOAD_TAB',
  LOAD_FAIL: 'job/LOAD_FAIL',
  CLONE_JOB: 'jobs/CLONE_JOB',
  // Reset reducer
  RESET_MESSAGE: 'job/RESET_MESSAGE',
  DETAILS: 'job/DETAILS',
  CNV_QUESTION: 'job/CNV_QUESTION',
  GET_JOB_CANDIDATES_BY_ID: 'job/GET_JOB_CANDIDATES_BY_ID',
  GET_JOB_VERTICALIZATION_DATA: 'job/GET_JOB_VERTICALIZATION_DATA',
  GET_JOB_FUNCTION_QUESTIONS: 'job/GET_JOB_FUNCTION_QUESTIONS',
  GET_CAND_JOB_FUNCTION_ANSWERS: 'job/GET_CAND_JOB_FUNCTION_ANSWERS',
  GET_CAND_JOB_FUNCTION_QUESTIONS: 'job/GET_CAND_JOB_FUNCTION_QUESTIONS',
  GET_CANDIDATE_BY_ID: 'job/GET_CANDIDATE_BY_ID',
  UPDATE_FILTERED_PROFILES: 'job/UPDATE_FILTERED_PROFILES',
  SET_FEEDBACK_EXTERNAL_LINK: 'job/SET_FEEDBACK_EXTERNAL_LINK',
  SELECTED_INTERVIEW_CANDIDATES: 'job/SELECTED_INTERVIEW_CANDIDATES',
  PENDING_JOB_LOAD: 'job/PENDING_JOB_LOAD',
  PENDING_JOB_SUCCESS: 'job/PENDING_JOB_SUCCESS',
  GET_EMPLOYER_FEEDBACK: 'job/GET_EMPLOYER_FEEDBACK',
  EMPLOYER_FEEDBACK_LOADING: 'job/EMPLOYER_FEEDBACK_LOADING',
  SET_VISITED: 'job/SET_VISITED',
  GET_SKILLS: 'GET_SKILLS',
  LOAD_STOP_SKILLS: 'LOAD_STOP_SKILLS',
  LOAD_SKILLS: 'LOAD_SKILLS',
  CANDIDATE_FEEDBACK_LOADING: 'job/CANDIDATE_FEEDBACK_LOADING',
  GET_CANDIDATE_FEEDBACK: 'job/GET_CANDIDATE_FEEDBACK',
};
