import { notificationsConstants } from './constants';
import {
  typeCastToKeyValueObject,
  strictValidNumber,
  strictValidArray,
} from '../../utils/commonUtils';
import { validLoggedInUser } from '../../utils/siteSpecificCommonUtils';
import { errorConstants } from '../../redux/constants';

/**
 * Load notifications for  user
 * @param fetchOnlyCount: boolean
 * Access control: client/candidate
 */
export const loadNotifications =
  (fetchOnlyCount = false) =>
  async (dispatch, getState, api) => {
    dispatch({
      type: notificationsConstants.LOAD,
    });
    try {
      const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
      // Call api to load notifications
      let res = null;
      if (!fetchOnlyCount) {
        const params = {
          page: 1,
          limit: 100,
        };
        res = await api.get('/notifications', { params });
        const { rows, count, unread } = res.data;
        // If valid response continue
        await dispatch({
          type: notificationsConstants.LOAD_NOTIFICATIONS,
          notifications: strictValidArray(rows) ? rows : [],
          count,
          unread,
        });
      } else if (validLoggedInUser(user)) {
        res = await api.get('/notifications/count');
        const { unread } = res.data;
        // If valid response continue
        await dispatch({
          type: notificationsConstants.LOAD_NOTIFICATIONS,
          notifications: getState().get('notifications').get('notifications'),
          count: getState().get('notifications').get('count'),
          unread,
        });
      }
      dispatch({
        type: notificationsConstants.LOAD_SUCCESS,
      });
    } catch (err) {
      // If an error occurs, set error field
      dispatch({
        type: notificationsConstants.LOAD_FAIL,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: 'no notification found',
      });
    }
  };

/**
 * Read notification
 * @param id: string
 * Access control: client/candidate
 */
export const readNotification = (id) => async (dispatch, getState, api) => {
  try {
    // Call api to update the unread count for notifications
    const res = await api.post(`notifications/read/${id}`);
    const { unread } = res.data;
    // if valid response continue
    await dispatch({
      type: notificationsConstants.UPDATE_NOTIFICATIONS_COUNT,
      unread: strictValidNumber(unread) ? unread : 0,
    });
    return true;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: notificationsConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: 'no notification Update',
    });
    return false;
  }
};

/**
 * notification detail
 * @param id: string
 * Access control: client/candidate
 */
export const notificationDetail = (id) => async (dispatch, getState, api) => {
  await dispatch({
    type: notificationsConstants.NOTIFICATION_DETAIL,
    detail: {},
  });
  try {
    // Call api to update the unread count for notifications
    const res = await api.get(`notifications/details/${id}`);
    // if valid response continue
    await dispatch({
      type: notificationsConstants.NOTIFICATION_DETAIL,
      detail: res.data,
    });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: notificationsConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    return false;
  }
};

/**
 * notification detail
 * @param id: string
 * Access control: client/candidate
 */
export const clearDetails = () => async (dispatch) => {
  await dispatch({
    type: notificationsConstants.NOTIFICATION_DETAIL,
    detail: {},
  });
};

/**
 * notification detail
 * @param id: string
 * Access control: client/candidate
 */
export const updateRTN = (list) => async (dispatch) => {
  await dispatch({
    type: notificationsConstants.UPDATE_RTN,
    list,
  });
};
