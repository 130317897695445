import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../pr-loading';
import './pr-button.scss';

const PrButton = ({ value, onClick, type, isDisabled, isLoading, icon }) => {
  return (
    <div
      className={`pr-button-wrapper ${type || ''} ${isDisabled ? 'disable' : ''} ${
        isLoading ? 'loading' : ''
      }`}
      onClick={(e) => onClick && onClick(e)}
    >
      {isLoading && <Loading type="button" color="#fff" />}
      {!isLoading && icon ? <img src={icon} alt=""></img> : null}
      {!isLoading && <p>{value}</p>}
    </div>
  );
};

PrButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

PrButton.defaultProps = {
  onClick: undefined,
  type: '',
  isLoading: false,
  isDisabled: false,
  icon: null,
};

export default PrButton;
