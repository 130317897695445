/* eslint-disable no-console */
import { settingsConstants } from '../constants/settings-constants';
import {
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
  removeInValidKeyValuePairs,
} from '../../utils/commonUtils';
import { messages } from '../../language';
import { errorConstants } from '../constants';

/**
 * To load settings object which contain jobFunctions <Array>
 * Access role allowed - any
 */
export const loadSettings = (parm) => async (dispatch, getState, api) => {
  let data = parm;
  const settingData = removeInValidKeyValuePairs(getState().get('settings').toJS(), true);
  if (!strictValidArrayWithLength(data)) {
    data = [
      'jobfunctions',
      'industries',
      'educations',
      'benefits',
      'companycultures',
      'regions',
      'titles',
      'location_label',
      'client_hear_about_us',
      'markup',
      'company_size',
      'close_reason',
      'timesheet_missed_break_reason'
    ];
  }

  // parseKeys is to map querystring with saved keys in redux
  const parseKeys = {
    jobfunctions: 'jobFunctions',
    companycultures: 'cultures',
  };

  // reverseParseKeys is to map saved keys in redux with  querystring
  const reverseParseKeys = {
    jobFunctions: 'jobfunctions',
    cultures: 'companycultures',
  };
  // formatKeys is to map querystring with get from api res
  const formatKeys = {
    jobfunctions: 'job_functions',
    companycultures: 'company_cultures',
  };
  const settingKeys = data.map((v) => {
    if (validObjectWithParameterKeys(parseKeys, [v])) {
      return parseKeys[v];
    }
    return v;
  });

  // If setting data is already present then return
  if (validObjectWithParameterKeys(settingData, settingKeys)) {
    return settingData;
  }
  // below filter will filter out the keys which already present in redux
  data = settingKeys
    .filter((v) => {
      if (
        validObjectWithParameterKeys(settingData, [v]) &&
        strictValidArrayWithLength(settingData[v])
      ) {
        return false;
      }
      return true;
    })
    .map((v) => {
      if (validObjectWithParameterKeys(reverseParseKeys, [v])) {
        return reverseParseKeys[v];
      }
      return v;
    });
  dispatch({
    type: settingsConstants.LOAD,
  });
  try {
    const postURL = `/settings?select=${data.join(',')}`;
    const dataKeys = data.map((v) => {
      if (validObjectWithParameterKeys(formatKeys, [v])) {
        return formatKeys[v];
      }
      return v;
    });

    // Get job functions from api
    // let postURL =
    //   '/settings?select=jobfunctions,industries,educations,benefits,companycultures,regions,titles';
    // if (strictValidArrayWithLength(data)) {
    //   postURL = `/settings?select=${data.join(',')}`;
    //   dataKeys = data;
    // }
    const res = await api.get(postURL);
    const response =
      validObjectWithParameterKeys(res, ['data']) &&
      strictValidObjectWithKeys(res.data) &&
      res.data;
    const isValidReponseFlag = validObjectWithParameterKeys(response || {}, dataKeys);
    // validObjectWithParameterKeys(res.data, [
    //   'job_functions',
    //   'industries',
    //   'educations',
    //   'benefits',
    //   'company_cultures',
    //   'timezones',
    // ]);

    // If not valid response
    if (!isValidReponseFlag) {
      dispatch({
        type: settingsConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    const {
      job_functions,
      industries,
      educations,
      benefits,
      company_cultures,
      timezones,
      location_label,
      client_hear_about_us,
      markup,
      company_size,
      close_reason,
      resignation_notice,
      reason_for_leaving,
      titles,
      candidate_decline_reason,
      timesheet_missed_break_reason,
      msa_lovs,
    } = res.data;

    dispatch({
      type: settingsConstants.LOAD_SETTINGS,
      jobFunctions: job_functions,
      industries,
      educations,
      benefits,
      cultures: company_cultures,
      timezones,
      location_label,
      client_hear_about_us,
      markup,
      company_size,
      close_reason,
      resignation_notice,
      reason_for_leaving,
      titles,
      candidate_decline_reason,
      timesheet_missed_break_reason,
      msaLovs: msa_lovs,
      ...settingData,
    });
    dispatch({
      type: settingsConstants.LOAD_SUCCESS,
    });
    return res.data;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: settingsConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};


export const loadSettingsConfig = (parm) => async (dispatch, getState, api) => {
  let data = parm;

  try {
    const postURL = `/settings/config?select=${data.join(',')}`;
    const res = await api.get(postURL);
    return res;
  } catch (err) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};
