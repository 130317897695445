import {
  TEMPLATES_LIST_PROCESSING,
  TEMPLATES_LIST_SUCCESS,
  TEMPLATES_LIST_FAILURE,
  TEMPLATE_DELETE_PROCESSING,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_FAILURE,
} from './constants';

const initialState = {
  isLoading: false,
  error: null,
  templatesList: {
    count: 0,
    rows: [],
  },
  message: null,
};

export default function interviewTemplates(state = initialState, action) {
  switch (action.type) {
    case TEMPLATES_LIST_PROCESSING:
    case TEMPLATE_DELETE_PROCESSING: {
      return { ...state, isLoading: true, error: null, message: null };
    }
    case TEMPLATES_LIST_SUCCESS: {
      return { ...state, isLoading: false, templatesList: action.data };
    }
    case TEMPLATE_DELETE_SUCCESS: {
      return { ...state, isLoading: false, message: action.message };
    }
    case TEMPLATES_LIST_FAILURE:
    case TEMPLATE_DELETE_FAILURE: {
      return { ...state, isLoading: false, error: action.error };
    }
    default:
      return state;
  }
}
