export const jobShareConstants = {
  LOAD: 'job/share/LOAD',
  LOAD_SUCCESS: 'job/share/LOAD_SUCCESS',
  LOAD_FAIL: 'job/share/LOAD_FAIL',
  GET_ALL_CLIENTS: 'job/share/GET_ALL_CLIENTS',
  SET_CLIENT: 'job/share/SET_CLIENT',
  GET_ALL_LOCATIONS: 'job/share/GET_ALL_LOCATIONS',
  SET_CLIENT_LOCATION: 'job/share/SET_CLIENT_LOCATION',
  SUBMIT_LOAD: 'job/share/SUBMIT_LOAD',
  SUBMIT_SUCCESS: 'job/share/SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'job/share/SUBMIT_FAIL',
  // Flush
  FLUSH: 'job/share/FLUSH',
  RESET_MESSAGE: 'job/share/RESET_MESSAGE',
};
