import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import {
  JobList,
  Dashboard,
  TimesheetList,
  PlacementList,
  PlacementDetail,
  JobModule,
  Edit,
  ClientUsersList,
  Domains,
  ClientLocations,
  Profile,
  // DraftJob,
  Invoice,
  PostJob,
} from './loadableComponents';
import { Popup, PopupBody, PopupHeader, PopupFooter } from '../components/--primitives/pr-popup';
import PrButton from '../components/--primitives/pr-button';
import { authenticate, logout } from '../containers/auth/actions';
// import { loadPendingInfo } from '../containers/job/actions';
import {
  isAuthenticated,
  // validPendingJob,
} from '../utils/siteSpecificCommonUtils';
import RealTimeNotificationMessages from '../containers/real-time-notication-message';
import { typeCastToKeyValueObject } from '../utils/commonUtils';
import { setError } from '../redux/actions';
import { messages } from '../language';

function Routes({
  details,
  sessionExpired,
  callAuthenticateAPI,
  callLogoutAPI,
  // callLoadPendingJobAPI,
  user,
  isUserAdmin,
  dispatch,
}) {
  const initialization = async () => {
    const res = await callAuthenticateAPI();
    // callLoadPendingJobAPI({ user: res._id });
  };

  useEffect(() => {
    if (!isAuthenticated(user)) {
      initialization();
    }
    // else {
    //   callLoadPendingJobAPI({ user: user._id });
    // }
  }, []);
  const isClientAdvancedVersion = process.env.REACT_APP_ISCLIENTADVANCEDVERSION === 'true';

  return (
    <>
      {sessionExpired && (
        <Popup isOpen={sessionExpired} action="warning">
          <PopupHeader action="warning" />
          <PopupBody>
            <div className="content title">Session expired</div>
            <div className="content text">Your session has expired, please login to continue.</div>
          </PopupBody>
          <PopupFooter>
            <PrButton value="Go back to login" type="blue tall" onClick={() => callLogoutAPI()} />
          </PopupFooter>
        </Popup>
      )}
      {/* {validPendingJob(details) && <DraftJob />} */}
      <RealTimeNotificationMessages />
      <Switch>
        <Route
          exact
          path="/"
          component={isClientAdvancedVersion ? Dashboard : TimesheetList}
        />
        <Route exact path="/timesheets" component={TimesheetList} />
        <Route exact path="/timesheet/:id" component={TimesheetList} />
        <Route path="/invoice" component={Invoice} />
        <Route exact path="/placements" component={PlacementList} />
        <Route path="/placements/:id" component={PlacementDetail} />
        {isUserAdmin && (
          <Route exact path="/admin-functions/client-users-list" component={ClientUsersList} />
        )}
        {isUserAdmin && <Route exact path="/admin-functions/domains" component={Domains} />}
        <Route exact path="/admin-functions/locations" component={ClientLocations} />
        <Route exact path="/admin-functions/company-profile" component={Profile} />
        <Route exact path="/edit" component={Edit} />
        <Route path="/jobs" component={JobList} />
        <Route path="/post-a-job" component={PostJob} />
        <Route path="/job" component={JobModule} />
        <Route path="/interview-scheduled" component={Dashboard} />

        <Route
          path="*"
          exact
          render={() => {
            /*if (isUserAdmin)*/ dispatch(setError(messages.WRONG_URL));
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    </>
  );
}

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // callLoadPendingJobAPI: PropTypes.func.isRequired,
  callAuthenticateAPI: PropTypes.func.isRequired,
  user: PropTypes.shape(PropTypes.object),
  details: PropTypes.shape(PropTypes.object),
  isUserAdmin: PropTypes.bool,
  sessionExpired: PropTypes.bool,
};

Routes.defaultProps = {
  user: {},
  details: {},
  isUserAdmin: false,
  sessionExpired: false,
};

const mapStateProps = (state) => {
  const user = typeCastToKeyValueObject(state.get('auth').get('user'));
  const { role = '' } = user;
  const isUserAdmin = role === 'client-admin';
  return {
    user,
    isUserAdmin,
    details: typeCastToKeyValueObject(state.get('job').get('details')),
    isLoad: state.get('auth').get('isLoad'),
    loadErr: state.get('auth').get('loadErr'),
    sessionExpired: typeCastToKeyValueObject(state.get('auth').get('sessionExpired')),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callAuthenticateAPI: (...params) => dispatch(authenticate(...params)),
  // callLoadPendingJobAPI: (...params) => dispatch(loadPendingInfo(...params)),
  callLogoutAPI: (...params) => dispatch(logout(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(Routes);
