import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { dashboardConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  list: {
    count: 0,
    rows: [],
    account_manager_list: [],
  },
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case dashboardConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case dashboardConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case dashboardConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case dashboardConstants.GET_LIST:
      return state
        .set('list', typeCastResponse(action, 'list', 'object', {}))
        .set('isLoad', false)
        .set('loadErr', null);
    case dashboardConstants.GET_COUNT:
      return state
        .set('quickCount', typeCastResponse(action, 'quickCount', 'object', {}))
        .set('isLoad', false)
        .set('loadErr', null);
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
}
