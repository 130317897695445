/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  strictValidString,
  capitalizeFirstLetter,
  validObjectWithParameterKeys,
} from '../../../utils/commonUtils';
import { DEFAULT_COMPANY_NAME } from '../../../utils/siteSpecificConstants';

const Default = ({ details, user }) => {
  const { name } = user;
  const { body, job_title, company_name = DEFAULT_COMPANY_NAME, job = {} } = details;
  const { city = 'Mountain View', state = 'CA' } = job;
  if (strictValidString(body)) {
    return (
      <div className="content text">
        <p dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    );
  }

  return (
    validObjectWithParameterKeys(details, ['_id']) && (
      <div className="content text">
        <p>
          <b>Hello {capitalizeFirstLetter(name)},</b>
        </p>
        <p>
          An interview for the position of {job_title} - {city}, {state} has been scheduled for you.
        </p>
        <p>Company Name:- {company_name}</p>
        <p>Please check your E-Mail for more details... </p>
      </div>
    )
  );
};

Default.propTypes = {
  details: PropTypes.shape(PropTypes.object),
  user: PropTypes.shape(PropTypes.object),
};

Default.defaultProps = {
  details: {},
  user: {},
};

const mapStateToProps = (state) => {
  return {
    user: state.get('auth').get('user'),
    details: state.get('notifications').get('detail'),
  };
};

export default connect(mapStateToProps)(Default);
