import { validObjectWithParameterKeys, strictValidObjectWithKeys } from '../../utils/commonUtils';
// import { errorConstants } from '../constants/error.constants';
import { configConstants } from '../constants/config.constants';

/**
 * To tenant config data
 */
export const getTenantConfig = () => async (dispatch, getState, api) => {
  dispatch({ type: configConstants.LOAD });
  try {
    // Call api to fetch user data
    const res = await api.get('/tenant-config');

    // If not valid response object
    if (!validObjectWithParameterKeys(res, ['data']) && !strictValidObjectWithKeys(res.data)) {
      return false;
    }

    // Else if valid response continue
    dispatch({
      type: configConstants.TENANT_CONFIG,
      data: res.data,
    });
    dispatch({ type: configConstants.LOAD_SUCCESS });
  } catch (err) {
    dispatch({ type: configConstants.LOAD_FAIL });
    // dispatch({ type: errorConstants.SET_ERROR, error, });
  }
};
