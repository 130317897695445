import { combineReducers } from 'redux-immutable';
import manageUsers from '../../containers/admin-functions/manage-users/reducers';
import domains from '../../containers/admin-functions/domains/reducers';
import locations from '../../containers/admin-functions/manage-locations/reducers';
import companyProfile from '../../containers/admin-functions/profile/reducer';

export default combineReducers({
  manageUsers,
  domains,
  locations,
  companyProfile,
});
