import io from 'socket.io-client';
import {
  validObjectWithParameterKeys,
  strictValidObjectWithKeys,
  typeCastToKeyValueObject,
} from '../../utils/commonUtils';
import { notificationsConstants } from '../../containers/notifications/constants';
import { socketConstants } from '../constants/socket-constants';

let socket = {};

export const socketMiddleWare =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case socketConstants.DISCONNECT:
        if (strictValidObjectWithKeys(socket)) socket.disconnect();
        break;
      case socketConstants.JOIN:
        socket = io(`${process.env.REACT_APP_SOCKETHOST}`);
        socket.emit('join', action._id);
        // Ping on server and Update notification count
        socket.on('noti_count', (data) => {
          dispatch({
            type: notificationsConstants.UPDATE_NOTIFICATIONS_COUNT,
            unread: data.unread,
          });
        });
        // Pings when recieved a new notification
        socket.on('noti_received', (data) => {
          if (!validObjectWithParameterKeys(data, ['subject', 'unread', 'receiver'])) return;

          const { receiver, unread, sender_name, createdAt, _id, subject } = data;
          const user = typeCastToKeyValueObject(getState().get('auth').get('user'));

          if (validObjectWithParameterKeys(user, ['_id']) && user._id === receiver) {
            dispatch({
              type: notificationsConstants.LOAD_RTN,
              data: { subject, sender_name, createdAt, _id },
            });
            dispatch({
              type: notificationsConstants.UPDATE_NOTIFICATIONS_COUNT,
              unread,
            });
          }
        });
        break;
      default:
        break;
    }
    next(action);
  };
