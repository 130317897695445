/* eslint-disable no-console */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { companyProfileConstants } from './constants';
import { typeCastResponse } from '../../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  questions: [],
  currentStep: 0,
  selectedValues: {},
  savedType: '',
  direction: 'up',
  canSave: false,
});

const companyProfile = (state = initialState, action) => {
  switch (action.type) {
    case companyProfileConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case companyProfileConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case companyProfileConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case companyProfileConstants.GET_QUESTIONS:
      return state
        .set('questions', typeCastResponse(action, 'data', 'array', []))
        .set('savedType', action.savedType);
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
};

export default companyProfile;
