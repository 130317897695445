/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { jobConstants } from './constants';
import {
  strictValidArrayWithLength,
  typeCastResponse,
  validObjectWithParameterKeys,
} from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoadInner: false,
  isLoadtab: false,
  isLoad: false,
  message: null,
  loadErr: null,
  details: {},
  cnvQuestions: [],
  candidates: {},
  isPendingJobLoad: false,
  isFeedbackLoading: false,
  verticalizationData: [],
  jobFunctionQuestions: [],
  candJobFunctionAns: [],
  candJobFunctionQuestions: [],
  employerFeedbackList: {},
  feedbackExternalLinkData: {},
  candidateFeedbackList: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case jobConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case jobConstants.LOAD_INNER:
      return state.set('isLoadInner', true).set('loadErr', null);
    case jobConstants.EMPLOYER_FEEDBACK_LOADING:
    case jobConstants.CANDIDATE_FEEDBACK_LOADING:
      return state.set('isFeedbackLoading', true).set('loadErr', null);
    case jobConstants.PENDING_JOB_LOAD:
      return state.set('isPendingJobLoad', true).set('loadErr', null);
    case jobConstants.LOAD_TAB:
      return state.set('isLoadtab', true).set('loadErr', null);
    case jobConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('isFeedbackLoading', false)
        .set('isLoadtab', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case jobConstants.PENDING_JOB_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('isLoadtab', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'))
        .set('isPendingJobLoad', false);
    case jobConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('isLoadtab', false)
        .set('isFeedbackLoading', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'))
        .set('isPendingJobLoad', false);
    case jobConstants.GET_SKILLS:
      return state.set('skills', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.LOAD_SKILLS:
      return state.set('isSkillLoad', true).set('loadErr', null);
    case jobConstants.LOAD_STOP_SKILLS:
      return state.set('isSkillLoad', false).set('loadErr', null);
    // Reset reducer
    case jobConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    // Save job Details
    case jobConstants.DETAILS:
      return state.set('details', action.payLoad);
    case jobConstants.CNV_QUESTION:
      return state.set('cnvQuestions', action.data);
    case jobConstants.SET_FEEDBACK_EXTERNAL_LINK:
      return state.set('feedbackExternalLinkData', action.data);
    case jobConstants.USER_NOT_ALLOWED:
      return state
        .set('userNotAllowed', action.userNotAllowed)
        .set('userNotAllowedMsg', action.message);
    case jobConstants.SET_VISITED:
      const candidates = state.get('candidates');
      const matchedProfiles =
        (validObjectWithParameterKeys(candidates, ['matchedProfiles']) &&
          strictValidArrayWithLength(candidates.matchedProfiles) &&
          candidates.matchedProfiles.map((v) => {
            if (v._id === action.id) {
              v.is_visited = true;
            }
            return v;
          })) ||
        [];
      const savedProfiles =
        validObjectWithParameterKeys(candidates, ['savedProfiles']) &&
        strictValidArrayWithLength(candidates.savedProfiles) &&
        candidates.savedProfiles.map((v) => {
          if (v._id === action.id) {
            v.is_visited = true;
          }
          return v;
        });
      const recommendedProfiles =
        (validObjectWithParameterKeys(candidates, ['recommendedProfiles']) &&
          strictValidArrayWithLength(candidates.recommendedProfiles) &&
          candidates.recommendedProfiles.map((v) => {
            if (v._id === action.id) {
              v.is_visited = true;
            }
            return v;
          })) ||
        [];
      const passedProfiles =
        (validObjectWithParameterKeys(candidates, ['passedProfiles']) &&
          strictValidArrayWithLength(candidates.passedProfiles) &&
          candidates.passedProfiles.map((v) => {
            if (v._id === action.id) {
              v.is_visited = true;
            }
            return v;
          })) ||
        [];
      const selectedProfiles =
        (validObjectWithParameterKeys(candidates, ['selectedProfiles']) &&
          strictValidArrayWithLength(candidates.selectedProfiles) &&
          candidates.selectedProfiles.map((v) => {
            if (v._id === action.id) {
              v.is_visited = true;
            }
            return v;
          })) ||
        [];
      const likedProfiles =
        (validObjectWithParameterKeys(candidates, ['likedProfiles']) &&
          strictValidArrayWithLength(candidates.likedProfiles) &&
          candidates.likedProfiles.map((v) => {
            if (v._id === action.id) {
              v.is_visited = true;
            }
            return v;
          })) ||
        [];
      return state.set('candidates', {
        ...candidates,
        matchedProfiles,
        savedProfiles,
        recommendedProfiles,
        passedProfiles,
        selectedProfiles,
        likedProfiles,
      });
    case jobConstants.GET_JOB_CANDIDATES_BY_ID:
      return state.set('candidates', typeCastResponse(action, 'candidates', 'object', {}));
    case jobConstants.GET_EMPLOYER_FEEDBACK:
      return state.set('employerFeedbackList', typeCastResponse(action, 'list', 'object', {}));
    case jobConstants.GET_CANDIDATE_FEEDBACK:
      return state.set('candidateFeedbackList', typeCastResponse(action, 'list', 'object', {}));
    case jobConstants.UPDATE_FILTERED_PROFILES:
      return state.set('candidates', typeCastResponse(action, 'candidates', 'object', {}));
    case jobConstants.SELECTED_INTERVIEW_CANDIDATES:
      return state.set(
        'selectedCandidates',
        typeCastResponse(action, 'selectedCandidates', 'array', []),
      );
    case jobConstants.GET_CANDIDATE_BY_ID:
      return state.set('candidate', typeCastResponse(action, 'candidate', 'object', {}));
    case jobConstants.GET_JOB_VERTICALIZATION_DATA:
      return state.set('verticalizationData', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.GET_JOB_FUNCTION_QUESTIONS:
      return state.set('jobFunctionQuestions', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.GET_CAND_JOB_FUNCTION_ANSWERS:
      return state.set('candJobFunctionAns', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.GET_CAND_JOB_FUNCTION_QUESTIONS:
      return state.set('candJobFunctionQuestions', typeCastResponse(action, 'data', 'array', []));
    case LOCATION_CHANGE:
      return state
        .set('isLoadInner', false)
        .set('isLoadtab', false)
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', null)
        .set('details', {})
        .set('cnvQuestions', [])
        .set(
          'candidates',
          typeCastResponse(
            {
              candidates: {
                selectedProfiles: state.get('candidates').selectedProfiles,
              },
            },
            'candidates',
            'object',
            {},
          ),
        )
        .set('isPendingJobLoad', false)
        .set('jobFunctionQuestions', [])
        .set('verticalizationData', [])
        .set('candJobFunctionAns', []);
    // Default
    case jobConstants.CLONE_JOB:
    default:
      return state;
  }
}
