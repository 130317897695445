import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { typeCastResponse } from '../../../utils/commonUtils';
import { locationConstants } from './constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  list: {
    count: 0,
    rows: [],
  },
});

const locations = (state = initialState, action) => {
  switch (action.type) {
    case locationConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case locationConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case locationConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    case locationConstants.GET_ALL_LOCATIONS:
      return state
        .set('list', typeCastResponse(action, 'list', 'object', {}))
        .set('isLoad', false)
        .set('loadErr', null);
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
};

export default locations;
