import Immutable from 'immutable';
import { typeCastResponse } from '../../utils/commonUtils';
import { errorConstants } from '../constants';

const initialState = Immutable.fromJS({
  message: null,
  status: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case errorConstants.SET_ERROR:
      return state
        .set('message', typeCastResponse(action, 'error'))
        .set('status', action.status ? typeCastResponse(action, 'status') : null);
    case errorConstants.FLUSH:
      return state.set('message', null).set('status', null);
    default:
      return state;
  }
}
