import React from 'react';
import Loadable from '@loadable/component';
import Loading from '../components/--primitives/pr-loading';

/**
 * Pre Login modules
 */
export const Login = Loadable(() => import('../containers/auth/login'), {
  fallback: <Loading />,
});

export const ResetPassword = Loadable(() => import('../containers/auth/reset-password'), {
  fallback: <Loading />,
});

/**
 * Account activation Module
 */
export const ActivateAccountScreen = Loadable(
  () => import('../containers/auth/account-activation'),
  {
    fallback: <Loading />,
  },
);

/**
 * Dashboard
 */
export const Dashboard = Loadable(() => import('../containers/dashboard'), {
  fallback: <Loading />,
});
/**
 * Job-List
 */
export const JobList = Loadable(() => import('../containers/job/job-list/job-list'), {
  fallback: <Loading />,
});
/**
 * Post Job
 */
export const PostJob = Loadable(() => import('../containers/job/post/post'), {
  fallback: <Loading />,
});
/**
 * Timesheet List View
 */
export const TimesheetList = Loadable(() => import('../containers/timesheet'), {
  fallback: <Loading />,
});

/**
 * Timesheet List View
 */
export const PlacementList = Loadable(() => import('../containers/placement'), {
  fallback: <Loading />,
});

/**
 * Placement Details View
 */
export const PlacementDetail = Loadable(() => import('../containers/placement/details'), {
  fallback: <Loading />,
});

/**
 * Job View
 */
export const JobModule = Loadable(() => import('../containers/job'), {
  fallback: <Loading />,
});

// export const DraftJob = Loadable(() => import('../containers/job/draft'), {
//   fallback: <Loading/>,
// });

export const Details = Loadable(() => import('../containers/job/details'), {
  fallback: <Loading />,
});

export const CandidatesInFlight = Loadable(() => import('../containers/job/candidates-in-flight'), {
  fallback: <Loading />,
});

export const SeeMoreMatches = Loadable(() => import('../containers/job/see-more-matches'), {
  fallback: <Loading />,
});

export const EmployerFeedback = Loadable(() => import('../containers/job/feedback/list'), {
  fallback: <Loading />,
});

/**
 *Edit Details View
 */
export const Edit = Loadable(() => import('../containers/job/edit'), {
  fallback: <Loading />,
});

/**
 * Admin Functions Client User List View
 */
export const ClientUsersList = Loadable(
  () => import('../containers/admin-functions/manage-users/list'),
  {
    fallback: <Loading />,
  },
);

/**
 * Admin Functions Client Location List View
 */
export const ClientLocations = Loadable(
  () => import('../containers/admin-functions/manage-locations/list'),
  {
    fallback: <Loading />,
  },
);

/**
 * Admin Functions Domains
 */
export const Domains = Loadable(() => import('../containers/admin-functions/domains'), {
  fallback: <Loading />,
});

export const Profile = Loadable(() => import('../containers/admin-functions/profile'), {
  fallback: <Loading />,
});

/**
 * Hire Process
 */
export const Hire = Loadable(() => import('../containers/hire'), {
  fallback: <Loading />,
});

export const PageError = Loadable(() => import('../containers/404-not-found'), {
  fallback: <Loading />,
});

/**
 * Interview Scheduling Process
 */
export const InterviewTemplates = Loadable(
  () => import('../containers/job/job-interview-schedule/list'),
  {
    fallback: <Loading />,
  },
);

/**
 * Interview Scheduling Process
 */
export const InterviewFeedback = Loadable(
  () => import('../containers/external-requests/interview-feedback'),
  {
    fallback: <Loading />,
  },
);

/**
 * Invoice List
 */
export const Invoice = Loadable(() => import('../containers/invoices'), {
  fallback: <Loading />,
});
