import TagManager from 'react-gtm-module';
import {
  validObjectWithParameterKeys,
  strictValidString,
  typeCastToKeyValueObject,
  strictValidStringWithMinLength,
} from '../../utils/commonUtils';
import history from './history';
import { googleAnalyticsConstants } from '../constants/googleAnalytics.constants';

const trackPageForAnalytics = (location) => {
  const { pathname } = location || {};
  const windowLocationPath = (window.location.pathname + window.location.search).trim();
  // Record a pageview for the given page
  if (!strictValidStringWithMinLength(pathname)) return;
};

export const googleAnalyticsMiddleWare =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    const { location } = history;
    switch (action.type) {
      case googleAnalyticsConstants.CONNECT:
        if (
          strictValidString(process.env.REACT_APP_GAKEY) &&
          validObjectWithParameterKeys(action, ['_id'])
        ) {
          /* TagManager.initialize({gtmId: process.env.REACT_APP_GAKEY,
          dataLayer: {
            userId: action._id,
            userProject: 'Client Core'
          }
        }) */
        }
        break;
      case googleAnalyticsConstants.CREATE_EVENT:
        TagManager.dataLayer({
          dataLayer: { event: action.event, ...action.data },
          dataLayerName: 'actions',
        });
        // ReactGA.event(action.event);
        break;
      default:
        // TagManager.initialize({gtmId: process.env.REACT_APP_GAKEY})

        break;
    }
    next(action);
  };
