export const USER_ROLES = {
  CLIENT_ADMIN: 'client-admin',
  CLIENT_USER: 'client-user',
  CLIENT_INTERVIEWER: 'interviewer',
};

export const USER_ROLES_OPTIONS = [
  { label: 'All', selected: true },
  { label: 'Admin', value: 'client-admin' },
  { label: 'Standard', value: 'client-user' },
  { label: 'Interviewer', value: 'interviewer' },
];

export const PREPEND_LINKEDIN_VALUE = 'https://www.linkedin.com/in/';

export const CHECK_LINKED_IN_CASES = [
  'https://www.linkedin.com/in/',
  'https://www.linkedin.com/in/www.',
  'https',
  'http://www.linkedin.com/in/',
  'http',
  'www.linkedin.com/in/',
  'www.linkedin.com',
  'www.linkedin/',
  'www.linkedin',
  'www',
  'linkedin.com/in/',
  'linkedin.com',
  '.com',
  './',
  '/',
];

export const DATE_FORMAT_MDY = 'MM/DD/YYYY';

export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const DATE_FORMAT_PRETTY_MDY = 'MMMM DD, YYYY';

export const DATE_FORMAT_DDDD = 'dddd';

export const DATE_FORMAT_DDDD_WITH_TIME = 'dddd hh:mm A';

export const DATE_FORMAT_MDY_WITH_TIME = `${DATE_FORMAT_MDY} hh:mm A`;

export const DATE_FORMAT_HOUR = 'hh:mm A';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const DEFAULT_COMPANY_NAME = 'Closeloop Technologies';

export const JOB_STATUS = {
  IN_ACTIVE: 0,
  ACTIVE: 1,
  IN_PROGRESS: 2,
  ON_HOLD: 3,
  CLOSE: 4,
};
export const DEFAULT_PASSWORD_MIN_LENGTH = 8;

export const DEFAULT_PASSWORD_MAX_LENGTH = 20;

export const TIMESHEET_HOLIDAY_VERBIAGE = {
  CLIENT: {
    IS_PTO: 'Per your agreement with Premier, this employee will receive holiday pay',
    HEADER:
      'Employee should only enter holiday hours that were worked. For any inaccuracies, reject the timesheet to allow the employee to resubmit.',
  },
};

export const contractDurationOptions = [
  { value: '1 Day', label: '1 Day' },
  { value: '1 Week', label: '1 Week' },
  { value: '1 Month', label: '1 Month' },
  { value: '3 Months', label: '3 Months' },
  { value: '6 Months', label: '6 Months' },
  { value: 'Ongoing', label: 'Ongoing' },
  { value: 'Last Minute Hire', label: 'Last Minute Hire' },
];
// export const closedReasonOptions = [
//   { value: 'Filled by Premier', label: 'Filled by Premier' },
//   {
//     value: 'Filled with an Existing Employee',
//     label: 'Filled with an Existing Employee',
//   },
//   { value: 'Filled by Internal Team', label: 'Filled by Internal Team' },
//   {
//     value: 'Filled by Outside Agency',
//     label: 'Filled by Outside Agency',
//   },
//   { value: 'Hire No Longer Needed', label: 'Hire No Longer Needed' },
// ];

// export const CLIENT_HOW_HEARD_ABOUT_US_OPTIONS = [
//   { label: 'You reached out to me', value: 'You reached out to me' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'LinkedIn', value: 'LinkedIn' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Social Media', value: 'Social Media' },
//   { label: 'Networking Event', value: 'Networking Event' },
//   { label: "I'm a previous candidate", value: "I'm a previous candidate" },
//   { label: "I'm a returning client", value: "I'm a returning client" },
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Web Search', value: 'Web Search' },
//   { label: 'Yelp', value: 'Yelp' },
//   { label: 'I was referred', value: 'I was referred' },
//   { label: 'Other', value: 'Other' },
// ];

// export const COMPANY_SIZE_OPTIONS = [
//   { label: 'Small (1 to 50 Employees)', value: 'Small'},
//   { label: 'Mid (50 to 500 Employees)', value: 'Mid'},
//   { label: 'Large (> 500 Employees)', value: 'Large'}
// ];
export const JOB_STATUS_OPTIONS = [
  { label: 'All' },
  { label: 'Open', value: 1, selected: true },
  { label: 'Draft', value: 2 },
  { label: 'On Hold', value: 3 },
  { label: 'Close', value: 4 },
];

export const JOB_TYPE_OPTIONS = {
  CONTRACT: 'Contract/Temporary',
  TEMPTOHIRE: 'Temp to Hire',
  PERMANENT: 'Permanent',
};

export const RECORD_TYPE_OPTION = {
  PERMANENT: 'Permanent',
};

export const EMPLOYMENT_TYPE_OPTIONS = [
  { label: 'All', selected: true },
  { label: JOB_TYPE_OPTIONS.CONTRACT, value: JOB_TYPE_OPTIONS.CONTRACT },
  { label: JOB_TYPE_OPTIONS.TEMPTOHIRE, value: JOB_TYPE_OPTIONS.TEMPTOHIRE },
  { label: JOB_TYPE_OPTIONS.PERMANENT, value: JOB_TYPE_OPTIONS.PERMANENT },
];

export const CLIENT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  PARTIAL_ACTIVE: 2,
  NEW: 3,
  INVITED: 4,
};

export const CLIENT_STATUS_OPTIONS = [
  { label: 'All', selected: true },
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
  { label: 'Partial Active', value: 2 },
  { label: 'New', value: 3 },
  { label: 'Invited', value: 4 },
];

export const GOOGLE_MAPS = {
  DEFAULT_LOCATION: { lat: 37.7749, lng: -122.4194 },
  DEFAULT_ZOOM: 10,
  DEFAULT_RADIUS: 10,
};

export const JOB_CANDIDATE_STATUS = {
  PENDING: 1,
  DROPPED: 2,
  SCHEDULED_FOR_INTERVIEW: 3,
  OFFER_MADE: 4,
  HOLD: 5,
  INTERVIEWED: 6,
  FOLLOW_UP_INTERVIEW: 3,
  OFFER_ACCEPTED: 7,
};

export const JOB_CANDIDATE_STATUS_TITLE = {
  PENDING: 'Pending Candidate <br/> Response',
  DROPPED: 'Dropped',
  SCHEDULED_FOR_INTERVIEW: 'Scheduling',
  OFFER_MADE: 'Offer Made',
  INTERVIEWED: 'Interviewed',
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS = {
  DEFAULT: 0,
  SCHEDULED: 1,
  CONFLICT: 2,
  INTERVIEW_TIME_UP: 3,
  // Virtually by default all dropped bucket's
  // rejected and declined job candidate handled with status 2 and sub_status : 4
  // PASSED_ON: 4,
  DECLINED_BY_CLIENT: 5,
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS_TITLE = {
  DEFAULT: 'Scheduling',
  SCHEDULED: 'Scheduled',
  CONFLICT:
    // eslint-disable-next-line max-len
    'There is a scheduling conflict. Please click "Add Availability" to provide more times for the candidate to select from.',
  INTERVIEW_TIME_UP: 'Interview Time Up',
};

export const GENDER_PRONOUN_OPTIONS = [
  { value: 'She, Her, Hers', label: 'She, Her, Hers' },
  { value: 'He, Him, His', label: 'He, Him, His' },
  { value: 'Ze, Zir, Zirs', label: 'Ze, Zir, Zirs' },
  { value: 'They, Them, Theirs', label: 'They, Them, Theirs' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const PLACEMENT_RECORD_TYPE_OPTIONS = [
  { label: 'All', selected: true },
  { label: JOB_TYPE_OPTIONS.PERMANENT, value: JOB_TYPE_OPTIONS.PERMANENT },
  { label: 'Temp', value: 'Temp' },
  { label: 'Payroll', value: 'Payroll' },
  // { label: 'VMS', value: 'vms' },
];

export const defaultCompanyLogo =
`${process.env.REACT_APP_S3_URL}/images/companies/company.png`;

export const CAND_FEEDBACK_DECLINED_REASONS = [
  { label: 'Accepted a job', value: 1000 },
  { label: 'Compensation / benefits', value: 1001 },
  { label: 'Hiring / interview process', value: 1002 },
  { label: "Job didn't align with expectations", value: 1003 },
  { label: 'Location / commute', value: 1004 },
  { label: 'Looking for something more aligned with degree / experience', value: 1005 },
  { label: 'No longer looking for a new opportunity', value: 1006 },
  { label: 'Not interested in company', value: 1007 },
  {
    label: 'Not interested in employment type (contract/temporary, temp to hire, or permanent)',
    value: 1008,
  },
  { label: 'Not interested in industry', value: 1009 },
  { label: 'Not interested in job function ', value: 1010 },
  { label: 'Not interested in job type (full time, part-time, or schedule)', value: 1011 },
  { label: "Values don't align", value: 12 },
];

// helper Text string for compensation
export const COMPENSATION_HELPER_TEXT = `For ${JOB_TYPE_OPTIONS.CONTRACT} and ${JOB_TYPE_OPTIONS.TEMPTOHIRE} searches, the hourly bill rate includes the candidate pay rate + employment and service costs.`;
