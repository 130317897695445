export const messages = {
  DEFAULT_ERROR_MESSAGE: 'Oops!! something went wrong, please try again',
  // Account Manager
  ACCOUNT_MANAGER: {
    VALID_SUBJECT: 'Please enter a valid subject',
    VALID_MESSAGE: 'Please enter a valid message',
  },
  DUPLICATE_ACCOUNT: (name = 'This domain') => `${name} is already added on your account`,
  // Validations
  VALIDATIONS: {
    REQUIRED: '* Required',
    FEEDBACK_REQUIRED: 'Please enter the feedback',
    REMOTE_LOVS_REQUIRED: 'Please select one option',
    AT_LEAST_ONE_INTERVIEW_TYPE_IS_REQUIRED: 'Please select the Interview Type',
    AT_LEAST_ONE_INTERVIEWER_IS_REQUIRED: 'Please add at least one Interviewer',
    STATUS_IS_REQUIRED: 'Please select the status',
    ROLE_IS_REQUIRED: 'Please select the role',
    INVALID_EMAIL_ADDRESS: 'Please enter a valid email address',
    EMAIL_ALREADY_EXISTS: 'Email address is already taken',
    EMAIL_REQUIRED_FOR_FORGOT_PASSWORD: 'Please enter your registered email address',
    INVALID_URL: 'Please enter a valid url',
    COMPENSATION_ERROR: 'Max value cannot be less than min value.',
    DUPLICATE_DOMAIN: 'This domain has already been added to your account.',
    INVALID_COMPANY_NAME: 'Please enter your company name',
    INVALID_COMPANY_SIZE: 'Please enter your company size',
    EXCEEDED_ADDITIONAL_SKILLS: 'Only 10 skills can be added',
    INVALID_INDUSTRY: 'Please enter industry',
    INVALID_COMPANY_WEBSITE: 'Please enter your company website',
    INVALID_TITLE: 'Please enter a valid title',
    INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
    INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
    STRICT_VALID_INTEGER: 'Must be an integer',
    INVALID_FILE_NAME: 'Please select a valid file',
    INVALID_PASSWORD_FORMAT: 'Please enter your password as per the password requirements',
    INVALID_ZIP_CODE: 'Please enter a valid zip code',
    INVALID_JOB_TITLE: 'Please select a job title',
    INVALID_INDUSTRY_FOR_CANDIDATE: 'Please select the industry that you worked in',
    INVALID_INDUSTRY_FOR_CLIENT: 'Please select an industry',
    STRICT_VALID_VALUE: 'Please select a valid value',
    INVALID_SOURCE: 'Please select how you heard about us',
    INVALID_TELL_US_MORE: 'Please tell us more',
    REGION_ALREADY_EXISTS: 'This region already exists',
    INVALID_REGION_BOUNDARY_TYPE: 'Please select the type of area boundary',
    RESOURCE_IS_ALREADY_INCLUDED: 'Please select a different resource',
    AT_LEAST_ONE_BOUNDARY_IS_REQUIRED: 'Please provide at least one area that defines boundary',
    AT_LEAST_ONE_RESOURCE_IS_REQUIRED: 'Please select at least one resource is required',
    INVALID_NAME: 'Please enter name',
    STRICT_VALID_NAME: 'Please enter a valid name',
    INVALID_ROLE: 'Please select role',
    REGION_DOES_NOT_EXIST: 'No such region exists',
    DECLINE_REASON_MANDATORY: 'Please provide your reason for declining this job invite',
    INVALID_FIRST_NAME: 'Please enter your first name',
    INVALID_LAST_NAME: 'Please enter your last name',
    INVALID_PASSWORD: 'Please enter the password',
    INVALID_CONFIRM_PASSWORD: 'Please confirm your password',
    PASSWORD_CONF_PASS_UNMATCH: 'Passwords do not match',
    TIMESHEET_REJECT_REASON: 'Please provide the reason for rejecting this timesheet',
  },
  // Show talent form
  SHOW_TALENT_FORM: {
    JOB_NOT_EXIST: 'This job post no longer exists',
    INACTIVE_JOB_POST: 'This job post already exists',
    INVALID_JOB_POST:
      'This job post is incomplete. Please provide all required fields to see matched talent for this job',
    INVALID_JOB_DURATIONS: 'Select your job type',
    INVALID_EMPLOYMENT_TYPE: 'Select your employment type',
    INVALID_NO_OF_POSITIONS: 'Select at least one',
  },
  // Places auto complete constants
  PLACES_AUTO_COMPLETE: {
    NO_RESULTS: 'No results found',
    CITY_STATE_MANDATORY: 'Please provide your preferred city and state',
    CITY_MANDATORY: "Please enter city name in 'City, State' format",
    CITY_IS_ALREADY_INCLUDED: 'Please select a different city, as this city is already selected.',
    CITY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different city as this area is already included in an existing region',
    COUNTY_MANDATORY: "Please enter county name in 'County, State' format",
    COUNTY_IS_ALREADY_INCLUDED: 'Please select a different county',
    COUNTY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different county as this county is already included in an existing region',
    STATE_MANDATORY: 'Please enter state name',
    STATE_IS_ALREADY_INCLUDED: 'Please select a different state',
    STATE_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different state as this state is already included in an existing region',
    COMPLETE_ADDRESS: 'Please provide your complete address',
    PICK_FROM_SUGGESTION_LIST: 'Please select an address from the suggestions list',
  },
  FEEDBACK_LABEL_TEXT: `This feedback will be accessible to the client, but will not be shared directly with the candidate.`,

  // New job display header text
  JOB_HELPING_TEXT: {
    JOB_FUCNTION_TEXT: 'What type of job are you looking to fill?',
    JOB_TITLE_TEXT: 'Select the job title and level of relevant experience needed',
    DUTIES_REQ_TEXT_POST: 'Select all applicable duties and requirements',
    DUTIES_REQ_TEXT_EDIT:
      'Make changes and updates to your job duties & requirements, then click the "Save Changes" button below',
    JOB_DETAILS_TEXT_POST: 'Tell us about the job you want to fill',
    JOB_DETAILS_TEXT_EDIT:
      'Make changes and updates to your job details, then click the "Save Changes" button below',
    JOB_CULTURE_TEXT: 'Offer insight into your work environment, management styles, and more!',
    JOB_DESCRIPTION_TEXT: 'Got a job description on hand? Feel free to add it here',
    JOB_NOTES_TEXT: 'Notes are only visible to your team and ours',
    JOB_ADDITIONAL_INFO_POST: 'Complete your posting with descriptions and links',
    JOB_ADDITIONAL_INFO_EDIT:
      'Make changes and updates to your job additional information, then click the "Save Changes" button below',
  },
  // Calendar constants
  CALENDAR: {
    OUT_OF_BOUNDS: 'Please provide availability for a future time',
  },
  WRONG_URL: 'That link could not be found. We are bringing you to the Dashboard.',

  // General label text or verbiages
  GENERAL_LABEL_TEXT: {
    JOB_SHARE_POP_UP_HEADING: 'Share with users within your organization',
  },

  MSA_POP_UP_TEXT: `You do not currently have a signed Master Service Agreement 
  on file in our system. Your Account Manager has been notified
   and will be reaching out to complete this process.`,

  ROLE_HELPER_TEXT: {
    Interviewer:
      'Interviewer - Individuals added to the "Interviewers" section will automatically be saved as a "user" of the company, and will be assigned the role of "Interviewer." To activate a user with the role of "Interviewer" fill out the First Name, Last Name, and Role fields then press "Save Changes." An activation email will be sent to the user accordingly.',

    Standard:
      'Standard - A "Standard User" can access and action jobs where they have been designated as the Job Owner, and any jobs they have been shared.',

    Admin:
      'Admin - An "Admin User" can access and action all jobs and "Admin Functions" (e.g. adding/editing users, domains, locations, and company profile).',
  },

  COMPENSATION_I_TEXT: 'Include bonus, commission, or other compensation if relevant',

  PRIMARY_ADMIN_HELPER_TEXT:
    'A "Primary Admin User" can access and action all jobs and "Admin Functions" (e.g. adding/editing users, domains, locations, and company profile), and is also the primary contact for the account.',

  helpertext: {
    Interviewer:
      'Interviewer - Individuals added to the "Interviewers" section will automatically be saved as a "user" of the company, and will be assigned the role of "Interviewer."',
    Standard:
      'Standard - A "Standard User" can access and action jobs where they have been designated as the Job Owner, and any jobs they have been shared.',
    Admin:
      'Admin - An "Admin User" can access and action all jobs and "Admin Functions" (e.g. adding/editing users, domains, locations, and company profile).',
    PrimaryAdmin:
      'Primary Admin - A "Primary Admin User" can access and action all jobs and "Admin Functions" (e.g. adding/editing users, domains, locations, and company profile), and is also the primary contact for the account.',
  },
};
