import Immutable from 'immutable';
import { typeCastResponse } from '../../utils/commonUtils';
import { notificationsConstants } from './constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  list: [],
  rtnList: [],
  notification: null,
  count: 0,
  unread: 0,
  detail: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case notificationsConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case notificationsConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case notificationsConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('count', 0)
        .set('unread', 0)
        .set('list', [])
        .set('loadErr', typeCastResponse(action, 'error'));
    // Loading notifications into list
    case notificationsConstants.LOAD_NOTIFICATIONS:
      return state
        .set('list', typeCastResponse(action, 'notifications', 'array', []))
        .set('count', typeCastResponse(action, 'count', 'number', 0))
        .set('unread', typeCastResponse(action, 'unread', 'number', 0))
        .set('detail', {});
    case notificationsConstants.LOAD_RTN:
      return state.set('rtnList', [
        typeCastResponse(action, 'data', 'object', {}),
        ...state.get('rtnList'),
      ]);
    case notificationsConstants.UPDATE_RTN:
      return state.set('rtnList', typeCastResponse(action, 'list', 'array', [])).set('detail', {});
    // Loading notifications into list
    case notificationsConstants.UPDATE_NOTIFICATIONS_COUNT:
      return state.set('unread', typeCastResponse(action, 'unread', 'number', 0));
    case notificationsConstants.FLUSH:
      return initialState
        .set('message', null)
        .set('loadErr', null)
        .set('notifications', [])
        .set('notification', null)
        .set('count', 0)
        .set('unread', 0);
    case notificationsConstants.NOTIFICATION_DETAIL:
      return state
        .set('message', null)
        .set('loadErr', null)
        .set('detail', typeCastResponse(action, 'detail', 'object'));
    // Default
    default:
      return state;
  }
}
