import app from './app';
import error from './error';
import config from './config';
import auth from '../../containers/auth/reducers';
import notifications from '../../containers/notifications/reducers';
import job from '../../containers/job/reducers';
import settings from './settings';
import timesheet from '../../containers/timesheet/reducers';
import placement from '../../containers/placement/reducers';
import dashboard from '../../containers/dashboard/reducers';
import adminFunctions from './adminFunctions';
import shareJobModal from '../../containers/job/share-job/reducers';
import interviewTemplates from '../../containers/job/job-interview-schedule/list/reducer';
import scheduleInterview from '../../containers/job/job-interview-schedule/add/reducer';
import interviewSchedule from '../../containers/interview-schedule/reducer';
import { invoicesData } from '../../containers/invoices/reducer';

export default {
  app,
  error,
  config,
  auth,
  dashboard,
  notifications,
  job,
  settings,
  timesheet,
  shareJobModal,
  adminFunctions,
  interviewTemplates,
  scheduleInterview,
  interviewSchedule,
  invoicesData,
  placement,
};
