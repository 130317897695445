import React from 'react';
import PropTypes from 'prop-types';
import MainRoute from '../../routes';

const App = ({ history }) => {
  return (
    <React.Fragment>
      <MainRoute history={history} />
    </React.Fragment>
  );
};

App.propTypes = {
  history: PropTypes.shape(PropTypes.object),
};

App.defaultProps = {
  history: {},
};

export default App;
