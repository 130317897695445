export const SAVE_TEMPLATE_PROCESSING = 'SAVE_TEMPLATE_PROCESSING';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';
export const GET_TEMPLATE_PROCESSING = 'GET_TEMPLATE_PROCESSING';
export const SET_TEMPLATE_SUCCESS = 'SET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';
export const SEND_INVITE_PROCESSING = 'SEND_INVITE_PROCESSING';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAILURE = 'SEND_INVITE_FAILURE';
export const CANDIDATE_SLOTS_PROCESSING = 'CANDIDATE_SLOTS_PROCESSING';
export const CANDIDATE_SLOTS_SUCCESS = 'CANDIDATE_SLOTS_SUCCESS';
export const CANDIDATE_SLOTS_FAILURE = 'CANDIDATE_SLOTS_FAILURE';
export const INTERVIEW_DETAILS_PROCESSING = 'INTERVIEW_DETAILS_PROCESSING';
export const INTERVIEW_DETAILS_SUCCESS = 'INTERVIEW_DETAILS_SUCCESS';
export const INTERVIEW_DETAILS_FAILURE = 'INTERVIEW_DETAILS_FAILURE';
export const VALIDATE_INTERVIEWER_PROCESSING = 'VALIDATE_INTERVIEWER_PROCESSING';
export const VALIDATE_INTERVIEWER_SUCCESS = 'VALIDATE_INTERVIEWER_SUCCESS';
export const VALIDATE_INTERVIEWER_FAILURE = 'VALIDATE_INTERVIEWER_FAILURE';
export const SAVE_FEEDBACK = 'SAVE_FEEDBACK';
