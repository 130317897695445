import {
  SAVE_TEMPLATE_FAILURE,
  SAVE_TEMPLATE_PROCESSING,
  GET_TEMPLATE_PROCESSING,
  SET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAILURE,
  CANDIDATE_SLOTS_PROCESSING,
  CANDIDATE_SLOTS_SUCCESS,
  CANDIDATE_SLOTS_FAILURE,
  INTERVIEW_DETAILS_PROCESSING,
  INTERVIEW_DETAILS_SUCCESS,
  INTERVIEW_DETAILS_FAILURE,
  SAVE_FEEDBACK,
  VALIDATE_INTERVIEWER_PROCESSING,
  VALIDATE_INTERVIEWER_SUCCESS,
  VALIDATE_INTERVIEWER_FAILURE,
} from './constants';

const initialState = {
  isLoading: true,
  error: null,
  templateDetail: {},
  interviewDetail: {},
  feedback: '',
};

export default function scheduleInterview(state = initialState, action) {
  switch (action.type) {
    case SAVE_TEMPLATE_PROCESSING:
    case GET_TEMPLATE_PROCESSING:
    case CANDIDATE_SLOTS_PROCESSING:
    case INTERVIEW_DETAILS_PROCESSING:
    case VALIDATE_INTERVIEWER_PROCESSING: {
      return { ...state, isLoading: true, error: null };
    }
    case SET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templateDetail: action.payload.data,
      };
    }
    case SEND_INVITE_SUCCESS:
    case CANDIDATE_SLOTS_SUCCESS:
    case VALIDATE_INTERVIEWER_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case INTERVIEW_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        interviewDetail: action.payload.data,
      };
    }
    case SAVE_FEEDBACK: {
      return { ...state, feedback: action.feedback };
    }
    case SAVE_TEMPLATE_FAILURE:
    case GET_TEMPLATE_FAILURE:
    case SEND_INVITE_FAILURE:
    case CANDIDATE_SLOTS_FAILURE:
    case INTERVIEW_DETAILS_FAILURE:
    case VALIDATE_INTERVIEWER_FAILURE: {
      return { ...state, isLoading: false, error: action.error };
    }
    default:
      return state;
  }
}
