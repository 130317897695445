import moment from 'moment-timezone';
import {
  validObjectWithParameterKeys,
  strictValidString,
  strictValidNumber,
  formatNumber,
  strictValidArray,
  strictValidObject,
} from './commonUtils';
import { USER_ROLES, DEFAULT_TIMEZONE } from './siteSpecificConstants';

/**
 * If valid logged in user
 * @param user: object
 */
export const validUser = (user) =>
  validObjectWithParameterKeys(user, ['_id', 'status', 'name', 'first_name', 'email', 'role']) &&
  strictValidString(user.name) &&
  strictValidString(user.first_name) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role);

/**
 * If valid logged in user
 * @param user: object
 */
export const validLoggedInUser = (user) =>
  validUser(user) && !!user.status && strictValidString(user.role);

/**
 * Checks if a valid company user
 * @param user: object
 */
export const validCompany = (user) =>
  validLoggedInUser(user) &&
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_USER].indexOf(user.role) > -1;

/**
 * Checks if a valid job
 * @param job: object
 */
export const validJob = (job) => validObjectWithParameterKeys(job, ['_id', 'status']);

/**
 * Checks if a valid candidate user
 * @param user: object
 */
export const validCandidate = (user) =>
  validLoggedInUser(user) && user.role === USER_ROLES.CANDIDATE;

/**
 * authenticated from API and update the it store
 * @param user: object
 */
export const isAuthenticated = (user) =>
  validUser(user) &&
  validObjectWithParameterKeys(user, ['isAuthenticated']) &&
  user.isAuthenticated;

/**
 * Checks if a valid pending job
 * @param job: object
 */
export const validPendingJob = (job) =>
  validObjectWithParameterKeys(job, ['is_first']) && job.is_first && job.status === 2;

/**
 * Get job status
 * @param {*} status: number
 */
export const getJobStatus = (status) => {
  switch (status) {
    case 1:
      return 'Open';
    case 2:
      return 'Draft';
    case 3:
      return 'On Hold';
    case 4:
      return 'Closed';
    default:
      return '';
  }
};

/**
 * Get Placement status
 * @param {*} status: number
 */
export const getPlacementStatus = (status) => {
  switch (status) {
    case 'Active':
      return 'Guarantee Active';
    case 'Inactive':
      return 'Guarantee Inactive';
    case 'Fall Off':
      return status;
    default:
      return '';
  }
};
/**
 * If valid invited company user
 * @param user: object
 */
export const validInvitedCompanyUser = (user) =>
  validObjectWithParameterKeys(user, ['_id', 'email', 'status', 'role', 'activation_key']) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role) &&
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_USER, USER_ROLES.CLIENT_INTERVIEWER].indexOf(
    user.role,
  ) > -1 &&
  strictValidString(user.activation_key);

/**
 * Get minimum & maximum string
 * @param min: number
 * @param max: number
 * @param assistedText: string
 */
export const getMinMaxString = (min, max, assistedText, preAssistedText, formatting = false) =>
  (strictValidNumber(min) &&
    strictValidNumber(max) &&
    strictValidString(assistedText) &&
    ((strictValidString(preAssistedText) &&
      ((max &&
        min < max &&
        `${preAssistedText}${formatting ? formatNumber(min) : min} - ${preAssistedText}${
          formatting ? formatNumber(max) : max
        } ${assistedText}`) ||
        (min && `> ${preAssistedText}${formatting ? formatNumber(min) : min} ${assistedText}`))) ||
      (!strictValidString(preAssistedText) &&
        ((max &&
          min < max &&
          `${formatting ? formatNumber(min) : min} - ${
            formatting ? formatNumber(max) : max
          } ${assistedText}`) ||
          (min && `> ${formatting ? formatNumber(min) : min} ${assistedText}`))))) ||
  '';

export const getAddressStateObj = (location) => {
  const coordinates = validObjectWithParameterKeys(location, ['loc'])
    ? location.loc.coordinates
    : null;
  const locationCoordinates = coordinates ? { lat: coordinates[1], lng: coordinates[0] } : null;
  return {
    location_coordinates: locationCoordinates,
    timezone: validObjectWithParameterKeys(location, ['timezone']) ? location.timezone : null,
    address_2: validObjectWithParameterKeys(location, ['address_2']) ? location.address_2 : null,
    location: validObjectWithParameterKeys(location, ['address']) ? location.address : null,
    zipcode: validObjectWithParameterKeys(location, ['zipcode']) ? location.zipcode : null,
    city: validObjectWithParameterKeys(location, ['city']) ? location.city : null,
    state: validObjectWithParameterKeys(location, ['state']) ? location.state : null,
    county: validObjectWithParameterKeys(location, ['county']) ? location.county : null,
    country: validObjectWithParameterKeys(location, ['country']) ? location.country : null,
    location_id: validObjectWithParameterKeys(location, ['_id']) ? location._id : null,
  };
};

/**
 * Checks if a valid category
 * @param category: object
 */
export const validCategory = (category) =>
  validObjectWithParameterKeys(category, [
    '_id',
    'title',
    'icon',
    // 'experiences',
    'titles',
  ]) &&
  strictValidString(category.title) &&
  strictValidString(category.icon) &&
  // strictValidArray(category.experiences) &&
  strictValidArray(category.titles);

/**
 * Checks if a valid education
 * @param education: object
 */
export const validEducation = (education) =>
  validObjectWithParameterKeys(education, ['_id', 'title']) && strictValidString(education.title);

export const getUTCDate = (date) => (date === null ? null : moment(date).format('YYYY-MM-DD'));

/**
 * Checks if a valid role
 * @param role: object
 */
export const validRole = (role) =>
  validObjectWithParameterKeys(role, ['_id', 'title', 'duties']) &&
  strictValidString(role.title) &&
  strictValidArray(role.duties);

/**
 * Checks if a valid industry
 * @param industry: object
 */
export const validIndustry = (industry) =>
  validObjectWithParameterKeys(industry, ['_id', 'title']) && strictValidString(industry.title);
/**
 * Checks if a valid duty
 * @param duty: object
 */
export const validDuty = (duty) =>
  validObjectWithParameterKeys(duty, ['_id', 'title']) && strictValidString(duty.title);

export const getTimezone = (timezone) =>
  (strictValidString(timezone) && timezone) || DEFAULT_TIMEZONE;

export const getTimezoneStr = (timezone) => moment.tz(getTimezone(timezone)).format('z');

export const formatTimezoneStr = (timezone) => getTimezoneStr(timezone).replace('DT', 'ST');

export const getJobRemoteLOVs = (id) => {
  switch (id) {
    case 0:
      return 'No';
    case 1:
      return '100% remote';
    case 2:
      return 'Remote optional';
    case 3:
      return 'Temporarily remote due to special circumstance';
    default:
      return '';
  }
};

export const getRemoteLOVs = (id) => {
  switch (id) {
    case 1:
      return 'Yes, I only want to work remotely';
    case 2:
      return "Yes, I'm open to remote work";
    case 0:
      return "No, I'm not open to remote work";
    default:
      return '';
  }
};

export const sortArrayObjectBy = (arrayVal, sortBy) =>
  arrayVal.sort((a, b) => {
    if (strictValidObject(a) && strictValidObject(b)) {
      const nameA = a[sortBy].toLowerCase(); // ignore upper and lowercase
      const nameB = b[sortBy].toLowerCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    return 0;
  });
