import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import { Provider } from 'react-redux';
import momentLocalizer from 'react-widgets-moment';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import { store, history } from './redux/lib/createStore';
import App from './containers/app';
import 'moment/locale/en-gb';
import './index.scss';
import ErrorPopup from './containers/error-popup';

Moment.locale('en-gb');
// Commenting this code intentionally will revert this after reliable sollution by neeraj on 10th sep 2021
// momentLocalizer();

if (process.env.REACT_APP_GAKEY && window.location.origin === process.env.REACT_APP_ORIGIN)
  TagManager.initialize({ gtmId: process.env.REACT_APP_GAKEY });

ReactDOM.render(
  <Provider store={store}>
    <ErrorPopup />
    <App history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
