import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  strictValidArrayWithLength,
  typeCastToKeyValueObject,
  validObjectWithParameterKeys,
} from '../../utils/commonUtils';
import {
  notificationDetail,
  clearDetails,
  updateRTN,
  readNotification,
} from '../notifications/actions';
import { DEFAULT_TIMEZONE, DATE_FORMAT_MDY } from '../../utils/siteSpecificConstants';
import './real-time-notification-message.scss';
import Default from '../notifications/type/default';
import NotificationPopup from '../notifications/notification-popup';

const RealTimeNotificationMessage = ({
  callNotificationDetailAPI,
  callReadNotificationAPI,
  rtnList,
  callUpdateRTN,
  callClearDetail,
  details,
  isLoad,
}) => {
  const listRef = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [list, setList] = useState([]);
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    if (content) {
      setIsModalOpen(true);
    }
  }, [content]);

  useEffect(() => {
    setList(rtnList);
    setTimeout(() => {
      const updatedList =
        strictValidArrayWithLength(rtnList) &&
        rtnList.map((v, index) => {
          const newMapping = v;
          if (index === 0) newMapping.is_read = true;
          return newMapping;
        });
      setList(updatedList);
    }, 2500);
  }, [rtnList]);

  useEffect(() => {
    if (strictValidArrayWithLength(list)) {
      setCardHeight(
        listRef.current.querySelector('li > .card-content').getBoundingClientRect().height,
      );
    }
  }, [list]);

  const toggle = () => {
    if (isModalOpen) callClearDetail();
    setIsModalOpen((state) => !state);
  };

  const openNotification = (_id, type) => {
    let component = null;
    callReadNotificationAPI(_id);
    callNotificationDetailAPI(_id);
    switch (type) {
      // case 'job-invite-accepted':
      //   loadRoute(dispatch, `/candidate-in-flight/${job._id}`);
      //   break;
      // case 'job-invite-rejected':
      //   loadRoute(dispatch, `/candidate-in-flight/${job._id}`);
      //   break;
      default:
        component = <Default />;
    }
    setContent(component);
  };

  const closeNotification = (e, _id) => {
    const card = e.target.closest('li');
    card.setAttribute('class', 'removed');
    const updatedList = list.map((v) => {
      const newMapping = v;
      if (v._id === _id) newMapping.is_read = true;
      return newMapping;
    });
    const onTransitionEnd = () => {
      if (e.propertyName === 'opacity') {
        callUpdateRTN(updatedList.filter((v) => !v.is_read));
        card.removeEventListener('transitionend', onTransitionEnd);
      }
    };
    card.addEventListener('transitionend', onTransitionEnd);
    setList(updatedList);
    e.stopPropagation();
  };

  return (
    <div
      ref={listRef}
      className="real-time-notification-message"
      style={{ '--card-height': `${cardHeight}px` }}
    >
      {!isLoad &&
        strictValidArrayWithLength(list) &&
        validObjectWithParameterKeys(details, ['_id']) && (
          <NotificationPopup isModalOpen details={details} content={content} toggle={toggle} />
        )}
      {strictValidArrayWithLength(list) &&
        list.map((v) => (
          <li
            key={v._id}
            className={v.is_read ? 'removed' : ''}
            onClick={() => openNotification(v._id)}
          >
            <div className="card-content">
              <div className="title-wrapper">
                <p className="title">{v.sender_name}</p>
                <p className="date">
                  {moment(v.createdAt).tz(DEFAULT_TIMEZONE).format(DATE_FORMAT_MDY)}
                </p>
              </div>
              <p className="description">{v.subject}</p>
              <div className="x-mark" onClick={(e) => closeNotification(e, v._id)}>
                <svg viewBox="0 0 20 20" fill="none">
                  <path
                    d="M0.500001 0.5L19.5 19.5M19.5 0.500001L0.5 19.5"
                    strokeWidth="1"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </li>
        ))}
    </div>
  );
};

RealTimeNotificationMessage.propTypes = {
  callReadNotificationAPI: PropTypes.func.isRequired,
  callUpdateRTN: PropTypes.func.isRequired,
  callClearDetail: PropTypes.func.isRequired,
  callNotificationDetailAPI: PropTypes.func.isRequired,
  rtnList: PropTypes.shape(PropTypes.array).isRequired,
  details: PropTypes.shape(PropTypes.object),
  isLoad: PropTypes.bool,
};

RealTimeNotificationMessage.defaultProps = {
  details: {},
  isLoad: false,
};

const mapStateProps = (state) => {
  return {
    details: typeCastToKeyValueObject(state.get('notifications').get('detail')),
    rtnList: state.get('notifications').get('rtnList'),
    isLoad: state.get('notifications').get('isLoad'),
    loadErr: state.get('notifications').get('loadErr'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callNotificationDetailAPI: (...params) => dispatch(notificationDetail(...params)),
  callClearDetail: (...params) => dispatch(clearDetails(...params)),
  callUpdateRTN: (...params) => dispatch(updateRTN(...params)),
  callReadNotificationAPI: (...params) => dispatch(readNotification(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(RealTimeNotificationMessage);
