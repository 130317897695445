import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { typeCastResponse } from '../../utils/commonUtils';
import { placementConstants } from './constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  list: {
    count: 0,
    rows: [],
  },
  detail: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case placementConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case placementConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case placementConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    // Loading notifications into list
    case placementConstants.LOAD_LIST:
      return state
        .set('list', typeCastResponse(action, 'list', 'object', {}))
        .set('isLoad', false)
        .set('loadErr', null);
    case placementConstants.GET_DETAILS:
      return state
        .set('detail', typeCastResponse(action, 'data', 'object', {}))
        .set('isLoad', false)
        .set('loadErr', null);
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
}
