/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Switch } from 'react-router';
import Footer from '../components/Footer';
import PrivateRoute from './privateRoute';
import { PublicRoute } from './publicRoute';
import {
  Login,
  ActivateAccountScreen,
  ResetPassword,
  Hire,
  InterviewFeedback,
} from './loadableComponents';
import { getTenantConfig } from '../redux/actions';
import {
  strictValidObjectWithKeys,
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
} from '../utils/commonUtils';

function MainRoutes({ history, callTenantConfigAPI, tenantConfig, configLoad }) {
  const callConfigAPI = async () => {
    await callTenantConfigAPI();
  };

  useEffect(() => {
    callConfigAPI();
  }, []);

  return (
    <>
      {!configLoad &&
        strictValidObjectWithKeys(tenantConfig) &&
        validObjectWithParameterKeys(tenantConfig, ['logo_favicon']) && (
          <Helmet>
            {strictValidArrayWithLength(tenantConfig.logo_favicon) &&
              tenantConfig.logo_favicon.map((v, i) => (
                <link key={i} rel="shortcut icon" sizes={v.key} href={v.url} />
              ))}
            <title>{`${tenantConfig.name} - Ajna`}</title>
          </Helmet>
        )}
      <ConnectedRouter history={history}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute
            exact
            path="/activate-invite-account/:key"
            component={ActivateAccountScreen}
          />
          <PublicRoute exact path="/activate-account/:key" component={ActivateAccountScreen} />
          <PublicRoute exact path="/reset-password/:key" component={ResetPassword} />
          <PublicRoute
            exact
            isExternalRoute
            path="/:type/feedback/:appointmentid/:key"
            component={InterviewFeedback}
          />
          <PublicRoute exact path="/hire" component={Hire} />
          <PrivateRoute path="/" />
        </Switch>
      </ConnectedRouter>
      <Footer />
    </>
  );
}

MainRoutes.propTypes = {
  history: PropTypes.shape(PropTypes.object),
  tenantConfig: PropTypes.shape(PropTypes.object),
  configLoad: PropTypes.bool,
};

MainRoutes.defaultProps = {
  history: {},
  tenantConfig: {},
  configLoad: false,
};

const mapStateProps = (state) => ({
  tenantConfig: state.get('config').get('tenantConfig'),
  configLoad: state.get('config').get('isLoad'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callTenantConfigAPI: (...params) => dispatch(getTenantConfig(...params)),
});

// export default MainRoutes;
export default connect(mapStateProps, mapDispatchToProps)(MainRoutes);
