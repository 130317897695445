import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Immutable from 'immutable';
import { routerMiddleware, connectRouter } from 'connected-react-router/immutable';
import history from './history';
import moduleReducers from '../reducers';
import ApiClient from './ApiClient';
import clientMiddleware from './clientMiddleware';
import ReactRootReducer from './reactRouterReducer';
import { socketMiddleWare } from './socket';
import { googleAnalyticsMiddleWare } from './googleAnalytics';

const api = new ApiClient();

const createAppReducer = (passedHistory) =>
  combineReducers({
    router: connectRouter(passedHistory),
    ...moduleReducers,
  });

const createRootReducer = (passedHistory) => (state, action) => {
  // empty the redux store on logout
  if (action.type === 'EMPTY_STORE') {
    return undefined;
  }

  return createAppReducer(passedHistory)(state, action);
};

function configureStore(passedHistory, preLoadedState) {
  const middlewares = [
    thunkMiddleware.withExtraArgument(api),
    clientMiddleware(api),
    routerMiddleware(passedHistory),
    socketMiddleWare,
    googleAnalyticsMiddleWare,
  ];

  if (process.env.NODE_ENV !== 'production') {
    if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
      const { logger } = require('redux-logger');
      middlewares.push(logger);
    }
  }

  const enhancer = compose(
    applyMiddleware(...middlewares),
    // other store enhancers if any,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (noop) => noop,
  );

  const initialState = Immutable.fromJS(preLoadedState || {});
  return createStore(createRootReducer(passedHistory), initialState, enhancer);
}

const store = configureStore(history, {});

export { store, history };
